import { useEffect, useState } from 'react';
import Web3 from 'web3';
export default function useWeb3() {
  const web3 = new Web3(window.ethereum);
  const [account, setAccount] = useState(sessionStorage.getItem("wallet"));
  const getAccount = async () => {
    if(!window.ethereum)
      return alert("Please install metamask");
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        if(accounts.length> 0) {
            // setAccount('0x5d7A6fB0AAA4B79c93DCD7A43039c49AB83BC995');
            // return  '0x5d7A6fB0AAA4B79c93DCD7A43039c49AB83BC995';
            setAccount(accounts[0]);
            return  accounts[0];
        } else {
            setAccount("");
            sessionStorage.removeItem("wallet");
            return "";
        }    
      } catch (error) {
        alert(error.message);
      }
    
  };
  useEffect(()=>{
    if(account!= null && account!= "") {
      sessionStorage.setItem('wallet', account);
    } else {
      sessionStorage.removeItem("wallet");
    }
    
  },[account])
  return {
    getAccount: getAccount,
    account: account,
    setAccount: setAccount,
  };
}

import React, { useState } from 'react';
import * as Yup from 'yup';
import { Typography, Button } from '@material-tailwind/react';
import './style.css';
import { Formik } from 'formik';
import useToken from '../App/useToken';

import TextInput from '../TextInput';
import Header from '../Header';
import Message from '../Message';
import { CHANGE_PASSWORD_URL, constructURLForRoute } from '../../urls';

function ChangePassword() {
  const [, setLoading] = useState(false);
  const [message, setMessage] = useState({
    code: '',
    type: 'error',
    message: ''
  });
  const { token } = useToken();
  //   useEffect(() => {
  //     if (message.code === 401) {
  //       setMessage({mes type:'error',message:'Session expired. Please login again'})
  //     }
  //   }, [message, setToken]);

  // perform some command
  const _onSave = (values, actions) => {
    setLoading(true);
    fetch(constructURLForRoute(CHANGE_PASSWORD_URL), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
      .then((r) => r.json())
      .then((data) => {
        setLoading(false);
        if (data.code) {
          setMessage({ ...data, type: 'error' });
        } else {
          setMessage({
            code: 0,
            type: 'success',
            message: 'Password updated successfully'
          });
        }
      })
      .catch((err) => {
        setMessage(err);
        setLoading(false);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <div className=" v-screen flex justify-center items-center">
      <div className="container mx-auto min-w-full min-h-screen bg-blue-600 bg-blue-gradiant">
        <Header active="/private" />

        <div className="container w-1/2 my-10 bg-white p-10 mx-auto rounded-lg">
          <Typography variant="h2">Change Password</Typography>
          <Formik
            initialValues={{
              oldPassword: '',
              password: '',
              confirmPassword: ''
            }}
            validationSchema={Yup.object({
              oldPassword: Yup.string().required('Required'),
              password: Yup.string()
                .required('Required')
                .min(8, 'Password is too short - should be 8 chars minimum.')
                .matches(
                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                  'Include at least one Uppercase, Lowercase, Number and a special character'
                ),
              confirmPassword: Yup.string().oneOf(
                [Yup.ref('password'), null],
                'Passwords must match'
              )
            })}
            onSubmit={(values, actions) => _onSave(values, actions)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,

              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="container">
                  <TextInput
                    type="password"
                    name="oldPassword"
                    placeholder="Old Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.oldPassword}
                    error={errors.oldPassword && touched.oldPassword && errors.oldPassword}
                    success={!errors.oldPassword && touched.oldPassword ? 'Valid' : ''}
                  />

                  <TextInput
                    type="password"
                    name="password"
                    placeholder="Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={errors.password && touched.password && errors.password}
                    success={!errors.password && touched.password ? 'Valid' : ''}
                  />
                  <TextInput
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    error={
                      errors.confirmPassword && touched.confirmPassword && errors.confirmPassword
                    }
                    success={!errors.confirmPassword && touched.confirmPassword ? 'Valid' : ''}
                  />
                  <div style={{ marginBottom: '30px', width: '100%' }}>
                    <Button
                      type="submit"
                      className="w-full"
                      size="lg"
                      onSubmit={handleSubmit}
                      color="lightBlue"
                      ripple="light"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Processing' : 'Change Password'}
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
          <Message type={message.type} message={message.message} />
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;

import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';
import ClockLoader from 'react-spinners/ClockLoader';
import { Typography } from '@material-tailwind/react';
import Header from '../Header';
import PropTypes from 'prop-types';

import useToken from '../App/useToken';
import useStorage from '../App/useStorage';
import useNetwork from '../App/useNetwork';
import './index.css';
import barIcon from './img/bar.png';
import pieIcon from './img/pie.png';
import { constructURLForNetwork, FETCH_PORTFOLIO_URL } from '../../urls';
import useWeb3 from '../App/useWeb3';

function Quote({ cite, footer }) {
  return (
    <>
      <p className="text-gray-700 text-base font-light leading-relaxed mt-0 mb-2"></p>
      <footer className="block text-gray-800">
        {footer}
        <cite>{cite}</cite>
      </footer>
    </>
  );
}

function H4({ children }) {
  return (
    <Typography variant="h4" color="gray">
      {children}
    </Typography>
  );
}

export default function Portfolio() {
  const { account } = useWeb3();
  const { network } = useNetwork();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = React.useState({});

  const getData = () => {
    if(!account)
      return;
    setLoading(true);
    fetch(constructURLForNetwork(network, FETCH_PORTFOLIO_URL), {
      method: 'GET',
      headers: {
        wallet: account
      }
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.code) {
          setErrorMsg(data);
        } else {
          setData(data);
        }
      })
      .catch(() => {
        setLoading(false);
      });
    // });
  }
  


  useEffect(() => {
    if (account !== "") {
      getData();
    }
  }, [account]);

  

  return (
    <div className=" v-screen flex justify-center items-center">
      <div className="container mx-auto min-w-full min-h-screen bg-blue-600 bg-blue-gradiant">
        <Header active="/" />
        <div className="container mx-auto flex w-48 my-10">
          <ClockLoader color="lightBlue" class="mx-auto" loading={loading} size={150} />
        </div>
        {data && data.liquidAssets != null && (
          <div className="container mx-auto flex my-10">
            <div className="bg-white py-5  w-full md:w-3/4 lg:w-1/1 mx-auto rounded-lg">
              <div className="bgC">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-y-6 gap-0 md:gap-x-4 m-2 sm:m-5">
                  <div className="grid grid-cols-1 rounded">
                    <div
                      className="grid grid-cols-2 bg-blue-700 p-4 rounded-t tbg height-40"
                      style={{ height: '80px' }}
                    >
                      <div className="text-white grid items-center">
                        <p className="text-base sm:text-2xl text-[20px] whitespace-nowrap">
                          <Link to={'/ido'}>Vesting and Staked</Link>
                        </p>
                      </div>
                      <div className="grid justify-items-end items-center">
                        <img className="w-1/3 sm:w-auto" src={pieIcon} alt="" />
                      </div>
                    </div>

                    <div className="bg-white py-7">
                      <div className="grid text-center">
                        <H4>
                          <CurrencyFormat
                            value={data.stakedAndVesting.totalAmount}
                            displayType="text"
                            thousandSeparator
                            prefix="$"
                          />
                        </H4>
                      </div>
                      <div className="grid p-3 text-gray-800 mt-3 sm:mt-7">
                        {data.stakedAndVesting.top.map((experience, i) => (
                          <div key={i}>
                            <Quote
                              color="gray"
                              footer={experience.label}
                              cite={
                                <CurrencyFormat
                                  value={experience.amount}
                                  displayType="text"
                                  thousandSeparator
                                  prefix="$"
                                />
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 rounded">
                    <div
                      className="grid grid-cols-2 bg-blue-700 p-4 rounded-t tbg height-40"
                      style={{ height: '80px' }}
                    >
                      <div className="text-white grid items-center">
                        <p className="text-base sm:text-2xl text-[20px] whitespace-nowrap">
                          <Link to={'/assets'}>Liquid Assets</Link>
                        </p>
                      </div>
                      <div className="grid justify-items-end items-center">
                        <img className="w-1/3 sm:w-auto" src={barIcon} alt="" />
                      </div>
                    </div>

                    <div className="bg-white py-7">
                      <div className="grid text-center">
                        <H4>
                          <CurrencyFormat
                            value={data.liquidAssets.totalAmount}
                            displayType="text"
                            thousandSeparator
                            prefix="$"
                          />
                        </H4>
                      </div>
                      <div className="grid p-3 text-gray-800 mt-3 sm:mt-7">
                        {data.liquidAssets.top.map((experience, i) => (
                          <div key={i}>
                            <Quote
                              color="gray"
                              footer={experience.label}
                              cite={
                                <CurrencyFormat
                                  value={experience.amount}
                                  displayType="text"
                                  thousandSeparator
                                  prefix="$"
                                />
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gap-x-8 grid md:grid-cols-3 mx-5 mt-8 gap-y-4 md:mb-6">
                  <div className="bg-white p-5 border-2 border-[#5db8e3] rounded-md tbrc">
                    <p className="text-xl uppercase font-semibold">Vesting</p>
                    <H4>
                      <CurrencyFormat
                        value={data.vesting.totalAmount}
                        displayType="text"
                        thousandSeparator
                        prefix="$"
                      />
                    </H4>
                    <br />
                    <br />
                    {data.vesting.top.length > 0 && <p className='font-bold'>Top Assets</p>}
                    {data.vesting.top.map((experience, i) => (
                      <div key={i}>
                        <Quote
                          color="gray"
                          footer={
                            <a
                              href={`https://www.coingecko.com/en/coins/${experience.coingeckoId}`}
                              // href={`https://poocoin.app/tokens/${experience.contractAddress}`}
                              target="_blank"
                              rel="noreferrer"
                              className='text-blue-900'
                            >
                              {' '}
                              {experience.name}
                            </a>
                          }
                          cite={
                            <CurrencyFormat
                              value={experience.amount}
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="bg-white p-5 border-2 border-[#5db8e3] rounded-md tbrc">
                    <p className="text-xl uppercase font-semibold">Staking</p>
                    <H4>
                      <CurrencyFormat
                        value={data.staking.totalAmount}
                        displayType="text"
                        thousandSeparator
                        prefix="$"
                      />
                    </H4>
                    <br />
                    <br />

                    {data.staking.top.length > 0 && <p className='font-bold'>Top Assets</p>}
                    {data.staking.top.map((experience, i) => (
                      <div key={i}>
                        <Quote
                          color="gray"
                          footer={
                            <a
                              href={`https://www.coingecko.com/en/coins/${experience.coingeckoId}`}
                              // href={`https://poocoin.app/tokens/${experience.contractAddress}`}
                              target="_blank"
                              rel="noreferrer"
                              className='text-blue-900'
                            >
                              {' '}
                              {experience.name}
                            </a>
                          }
                          cite={
                            <CurrencyFormat
                              value={experience.amount}
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="bg-white p-5 border-2 border-[#b5ddb7] rounded-md tbrc2">
                    <p className="text-xl uppercase font-semibold">In Wallets</p>
                    <H4>
                      <CurrencyFormat
                        value={data.inWallets.totalAmount}
                        displayType="text"
                        thousandSeparator
                        prefix="$"
                      />
                    </H4>
                    <br />
                    <br />

                    {data.inWallets.top.length > 0 && <p className='font-bold'>Top Assets</p>}
                    {data.inWallets.top.map((experience, i) => (
                      <div key={i}>
                        <Quote
                          color="gray"
                          footer={
                            <a
                              href={`https://www.coingecko.com/en/coins/${experience.coingeckoId}`}
                              target="_blank"
                              rel="noreferrer"
                              className='text-blue-900'
                            >
                              {' '}
                              {experience.name}
                            </a>
                          }
                          cite={
                            <CurrencyFormat
                              value={experience.amount}
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>

                <div className="grid">
                  <div className="gap-x-8 grid md:grid-cols-1 mx-5 mt-5  md:mb-6 tbn">
                    <p className="font-bold text-2xl tracking-wide">Largest Investments</p>
                  </div>
                  <div className="gap-x-8 grid md:grid-cols-4  mx-5 gap-y-4 md:mb-6 ">
                    {data.top4Investment.map((experience) => (
                      <div className="bg-[#22409a] rounded-[8px] px-2 py-2 tbgti">
                        <div className="flex border-b-2 border-white mt-8">
                          <div className="flex-none ">
                            <i className="fas text-white fa-wallet fa-3x" />
                          </div>
                          <div className="flex-1 tracking-wide justify-around m-2">
                            <p className="text-white ">
                              <CurrencyFormat
                                value={experience.amount}
                                displayType="text"
                                thousandSeparator
                                prefix="$"
                              />
                            </p>
                            <small className="text-right">
                              <a
                                // TODO: coingeckoId is undefined
                                // href={`https://www.coingecko.com/en/coins/${experience.coingeckoId}`}
                                href={`https://poocoin.app/tokens/${experience.contractAddress}`}
                                target="_blank"
                                rel="noreferrer"
                                className='text-white'
                              >
                                {' '}
                                {experience.name}
                              </a>
                            </small>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
